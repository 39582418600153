export const MenuItems=[
    {
        titles:'Home',
        url: '/Home',
        cName: 'nav-links',
    },
    {
        titles:'AboutUs',
        url: 'About',
        cName: 'nav-links',
    },
    {
        titles:'TruckType',
        url: 'TruckType',
        cName: 'nav-links',
    },
    {
        titles:'Contact',
        url: 'Contact',
        cName: 'nav-links',
    },
    
    ]